

























import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class KeyDialog extends Vue {
  @Prop({ default: '' })
  text!: string;

  get license() {
    return this.text.replace(/\n/gi, '\\n');
  }

  dialog = false;
}
