



























import { Vue, Component, Prop } from "vue-property-decorator";
import LicenseForm from "./LicenseForm.vue";
import state from "../../State";
import { License as BackendLicense, FrontendLicense } from "../../../../types";

export function fixPeriodVersionQuantity(licenseBody: FrontendLicense) {
  if (typeof licenseBody.type.version === "undefined") {
    licenseBody.type.version = 1.1;
  }
  switch (licenseBody.type.value) {
    case "free":
      licenseBody.period = "year";
      if (!licenseBody.quantity) licenseBody.quantity = 100;
      break;
    case "regular":
      licenseBody.period = "year";
      if (!licenseBody.quantity) licenseBody.quantity = 100;
      break;
    case "ultimate":
      licenseBody.period = "year";
      if (!licenseBody.quantity) licenseBody.quantity = 100;
      break;
    case "regular-one-year":
      licenseBody.period = "year";
      if (!licenseBody.quantity) licenseBody.quantity = 1;
      break;
    case "ultimate-one-year":
      licenseBody.period = "year";
      if (!licenseBody.quantity) licenseBody.quantity = 1;
      break;
    case "trial":
    default:
      licenseBody.period = "month";
      if (!licenseBody.quantity) licenseBody.quantity = 2;
      break;
  }
}

@Component({
  name: "UpdateLicense",
  components: { LicenseForm },
})
export default class UpdateLicense extends Vue {
  dialog = false;
  invalid = false;
  form: any = null; // eslint-ignore-line
  editedLicense!: BackendLicense;

  @Prop()
  private license!: BackendLicense;

  updateEditedLicense() {
    this.editedLicense = {
      ...this.license,
      ...{ type: this.license.type },
    };
  }

  created() {
    this.updateEditedLicense();
    this.$watch("license", () => {
      this.updateEditedLicense();
    });
  }

  prepareBody(license: FrontendLicense) {
    const body = { ...license };
    if (typeof body.usage !== "undefined") delete body.usage;
    if (body.statistics) {
      delete body.statistics;
    }
    fixPeriodVersionQuantity(body);
    return body;
  }

  onForm(form: any) {
    this.form = form;
  }

  save() {
    const valid = this.form.validate();
    if (!valid) {
      this.invalid = true;
      setTimeout(() => {
        this.invalid = false;
      }, 3000);
      return;
    }
    void fetch(state.get("request.url.licenses"), {
      ...state.get("request.params.api.put"),
      body: JSON.stringify(this.prepareBody(this.editedLicense)),
    })
      .then((res) => res.json())
      .then((data) => {
        this.dialog = false;
        this.$emit("update", data.license);
      });
  }

  close() {
    this.dialog = false;
  }
}
