var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-4"},[_vm._v("las la-pen-nib")]),_vm._v("Licenses "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"las la-search","label":"Search","single-line":"","hide-details":""},on:{"click:append":_vm.getLicenses,"keyup":_vm.searchKeyUp},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":_vm.getLicenses}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("las la-sync")]),_vm._v("Refresh ")],1),_c('add-license',{on:{"add":_vm.onAdd}}),_c('block-domains',{on:{"message":_vm.onMessage}}),_c('unblock-domains',{on:{"message":_vm.onMessage}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.model.licenses,"server-items-length":_vm.totalCount,"options":_vm.options,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm.dayjs(item.createdAt).format("YYYY-MM-DD")))]}},{key:"item.usage",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.usage ? item.usage.totalCount : 0))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.allOrigins || "no origins"))])])]}},{key:"item.licenses",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.licenses)+" "),_vm._l((item.onlyIPs),function(ip){return _c('v-btn',{key:ip,attrs:{"fab":"","small":"","elevation":"1"},on:{"click":function($event){return _vm.searchIP(ip)}}},[_c('v-icon',[_vm._v("las la-search")])],1)})]}},{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(item.email))])]}}],null,true)},[_c('span',[_vm._v("IP: "+_vm._s(item.ip ? item.ip : "none"))])])]}},{key:"item.validUntil",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(_vm.dayjs(item.validUntil).format("YYYY-MM-DD")))]}},{key:"item.domains",fn:function(ref){
    var item = ref.item;
return _vm._l((item.domains),function(domain){return _c('span',{key:domain,staticClass:"domain"},[(item.blockedDomains.includes(domain.split(':')[0]))?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("las la-ban")]):_vm._e(),_vm._v(_vm._s(domain))],1)})}},{key:"item.blocked",fn:function(ref){
    var item = ref.item;
return [(item.blocked)?_c('v-icon',[_vm._v("las la-ban")]):_vm._e()]}},{key:"item.repo",fn:function(ref){
    var item = ref.item;
return [(item.repository)?_c('a',{staticClass:"light-blue--text",attrs:{"href":item.repository,"target":"_blank"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("las la-external-link-alt")])],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","elevation":"1","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',[_vm._v("las la-trash")])],1),_c('update-license',{attrs:{"license":item},on:{"update":_vm.onUpdate}}),_c('show-statistics',{attrs:{"license":item}}),_c('show-key-dialog',{attrs:{"text":item.license.key}})]}}])}),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar.color,"rounded":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.snackbar.icon))]),_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }