

























































































import { Component, Vue, Watch } from "vue-property-decorator";
import state from "../../State";
import dayjs from "dayjs";
import AddLicense from "./AddLicense.vue";
import UpdateLicense from "./UpdateLicense.vue";
import ShowKeyDialog from "./ShowKeyDialog.vue";
import ShowStatistics from "./ShowStatistics.vue";
import BlockDomains from "./BlockDomains.vue";
import UnblockDomains from "./UnblockDomains.vue";
import { FrontendLicense, License as BackendLicense } from "../../../../types";

export interface Message {
  ok: boolean;
  message: string;
}

@Component({
  name: "Licenses",
  components: {
    AddLicense,
    UpdateLicense,
    ShowKeyDialog,
    ShowStatistics,
    BlockDomains,
    UnblockDomains,
  },
})
export default class Licenses extends Vue {
  dayjs = dayjs;
  headers = [
    { align: "left", text: "Created at", value: "createdAt" },
    { align: "left", text: "Last", value: "last" },
    { align: "left", text: "Valid", value: "validUntil" },
    { align: "left", text: "Usage", value: "usage" },
    { align: "left", text: "NumLic", value: "licenses" },
    { align: "left", text: "Company", value: "company" },
    { align: "left", text: "Email", value: "email" },
    { align: "left", text: "Type", value: "type.text" },
    //{ align: 'left', text: 'Ver', value: 'version' },
    //{ align: 'left', text: 'Quantity', value: 'quantity' },
    { align: "left", text: "Domains", value: "domains" },
    { align: "left", text: "Repo", value: "repo" },
    { align: "left", text: "Block", value: "blocked" },
    { align: "left", text: "Actions", value: "actions" },
  ];
  model: { licenses: BackendLicense[] } = { licenses: [] };
  search = "";
  lastSearch = "";
  message = "";
  snackbar = {
    visible: false,
    color: "warning",
    message: "",
    icon: "",
  };
  totalCount = 0;
  options = { page: 1, itemsPerPage: 15 };
  loading = false;

  @Watch("options", { deep: true })
  public watchOptions(options: any) {
    this.getLicenses();
  }

  getIps(license: FrontendLicense) {
    let licenses = [license._id];
    const ips: string[] = [];
    if (license._id !== "5f4e7c7ba8ecb77b79a106eb") {
      if (license.ips) {
        for (const ip of license.ips) {
          ips.push(ip.ip);
          for (const licId of ip.licenses) {
            licenses = [...new Set([...licenses, licId])];
          }
        }
        licenses = licenses.filter((licId) => {
          return licId !== "5f4e7c7ba8ecb77b79a106eb";
        });
      }
    }
    return { ips, licenses };
  }

  getLicenses() {
    this.loading = true;
    let { itemsPerPage } = this.options;
    if (!itemsPerPage) itemsPerPage = 15;
    if (this.search !== this.lastSearch) {
      this.options.page = 1;
    }
    const skip = this.options.page * itemsPerPage - itemsPerPage;
    this.lastSearch = this.search;
    void fetch(`${state.get("request.url.licenses")}?skip=${skip}&limit=${itemsPerPage}&search=${this.search}`, {
      ...state.get("request.params.api.get"),
    })
      .then((res) => res.json())
      .then((data) => {
        this.totalCount = data.totalCount;
        this.model.licenses = data.licenses.map((license: FrontendLicense) => {
          if (license.statistics && license.statistics.length) {
            license.usage = license.statistics[0];
            const allOrigins: string[] = license.usage.dates
              .map((date) => date.origins.map((origin) => origin.origin))
              .flat();
            license.allOrigins = [...new Set(allOrigins)].join(", ");
            license.last = license.usage.dates[license.usage.dates.length - 1].date;
          } else {
            license.usage = null;
            license.last = "";
          }
          license.licenses = 1;
          const { licenses, ips } = this.getIps(license);
          license.licenses = licenses.length;
          license.onlyIPs = ips;
          return license;
        });
      })
      .then(() => {
        this.loading = false;
      });
  }

  onAdd(license: BackendLicense) {
    this.model.licenses.push(license);
    this.snackbar.message = "License has been created.";
    this.snackbar.color = "success";
    this.snackbar.icon = "las la-check-square";
    this.snackbar.visible = true;
  }

  onMessage(data: Message) {
    this.snackbar.message = data.message;
    if (data.ok) {
      this.snackbar.color = "success";
      this.snackbar.icon = "las la-check-square";
    } else {
      this.snackbar.color = "error";
      this.snackbar.icon = "las la-exclamation";
    }
    this.snackbar.visible = true;
  }

  onUpdate(license: BackendLicense) {
    this.model.licenses = this.model.licenses.map((lic) => {
      if (lic._id === license._id) {
        const { licenses, ips } = this.getIps(license);
        license.licenses = licenses.length;
        license.onlyIPs = ips;
        return license;
      }
      return lic;
    });
    this.snackbar.message = "License has been updated.";
    this.snackbar.color = "success";
    this.snackbar.icon = "las la-check-square";
    this.snackbar.visible = true;
  }

  remove(license: BackendLicense) {
    fetch(state.get("request.url.licenses") + "/" + license._id, {
      ...state.get("request.params.api.delete"),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.ok) {
          this.model.licenses = this.model.licenses.filter((current: BackendLicense) => current._id !== license._id);
          this.snackbar.message = data.message;
          this.snackbar.color = "success";
          this.snackbar.icon = "las la-check-square";
          this.snackbar.visible = true;
        } else {
          this.snackbar.visible = true;
          this.snackbar.color = "error";
          this.snackbar.icon = "las la-bug";
          this.snackbar.message = data.message;
        }
      });
  }

  searchKeyUp(key: KeyboardEvent) {
    if (key.keyCode === 13) {
      this.getLicenses();
    }
  }

  searchIP(ip: string) {
    this.search = ip;
    this.getLicenses();
  }
}
