






















































import { Vue, Component, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { License as BackendLicense } from '../../../../types';

@Component({
  name: 'ShowStatistics'
})
export default class ShowStatistics extends Vue {
  dialog = false;
  dayjs = dayjs;

  @Prop()
  private license!: BackendLicense;

  close() {
    this.dialog = false;
  }
}
