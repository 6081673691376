





































import { Vue, Component } from "vue-property-decorator";
import state from "../../State";

@Component({
  name: "BlockDomains",
})
export default class AddLicense extends Vue {
  dialog = false;
  valid = true;
  domains = [];
  rules = [(value: string[]) => !!value.length || "This field is required"];
  delimeters = [" ", ","];
  form: any = null; // eslint-ignore-line

  prepareBody(domains: string[]) {
    const body = domains.slice();
    return body;
  }

  save() {
    void fetch(state.get("request.url.blocked-domains"), {
      ...state.get("request.params.api.post"),
      body: JSON.stringify(this.prepareBody(this.domains)),
    })
      .then((res) => res.json())
      .then((data) => {
        this.dialog = false;
        if (data.ok) {
          this.domains = [];
        }
        this.$emit("message", data);
      });
  }

  close() {
    this.dialog = false;
  }
}
