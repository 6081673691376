



























import { Vue, Component } from "vue-property-decorator";
import LicenseForm from "./LicenseForm.vue";
import state from "../../State";
import { FrontendLicense } from "../../../../types";
import dayjs from "dayjs";

@Component({
  name: "AddLicense",
  components: { LicenseForm },
})
export default class AddLicense extends Vue {
  dialog = false;
  invalid = false;
  license: FrontendLicense = this.getDefaultLicense();
  form: any = null; // eslint-ignore-line

  getDefaultLicense(): FrontendLicense {
    const validUntil = dayjs().format("YYYY-MM-DD HH:mm:ss");
    return {
      licenses: 1,
      blockedDomains: [],
      company: "",
      email: "",
      version: 3,
      type: { text: "Regular", value: "regular", version: 3 },
      rules: {
        updates: { minor: true, major: false },
        support: { ticketsPromised: 5, responseTimeDays: 5, validUntil },
        SaaS: false,
        maxDevelopers: 5,
        maxProjects: 1,
        extraPlugins: false,
      },
      quantity: 1,
      price: 0,
      currency: "USD",
      domains: [],
      repository: "",
      valid: false,
      blocked: false,
      offline: true,
    };
  }

  prepareBody(license: FrontendLicense) {
    const body = { ...license };
    return body;
  }

  onForm(form: any) {
    this.form = form;
  }

  save() {
    const valid = this.form.validate();
    if (!valid) {
      this.invalid = true;
      setTimeout(() => {
        this.invalid = false;
      }, 3000);
      return;
    }
    void fetch(state.get("request.url.licenses"), {
      ...state.get("request.params.api.post"),
      body: JSON.stringify(this.prepareBody(this.license)),
    })
      .then((res) => res.json())
      .then((data) => {
        this.dialog = false;
        this.license = this.getDefaultLicense();
        this.$emit("add", data.license);
      });
  }

  close() {
    this.form.reset();
    this.dialog = false;
  }
}
